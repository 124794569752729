import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { AuthApiService } from 'src/api/auth';
import { Injectable, OnDestroy } from '@angular/core';
import { ToasterService } from '../toaster/toaster.service';

@Injectable()
export class TokenValidatorService implements OnDestroy {
  constructor(private AuthApi: AuthApiService, private router: Router, private toaster: ToasterService) {}

  public init(): void {
    document.addEventListener('visibilitychange', this.checkAuthToken)
  }

  private checkAuthToken = () =>  {
    if (
      document.visibilityState === 'visible' && 
      !['/login', '/forgot-password', '/reset-password'].includes(location.pathname)
    ) {
      this.AuthApi.checkToken(this.AuthApi.token$.value)
        .pipe(catchError(error => {
          console.error('[TokenValidatorService] Unable to verify session:', error)
          this.toaster.error('Unable to verify your session')
          throw error
        }))
        .subscribe(isAuth => {
          if (!isAuth) {
            this.router.navigateByUrl('/login', { state: { redirectTo: location.pathname + location.search + location.hash} })           
          }
        })
    }
  }

  ngOnDestroy(): void {
    document.removeEventListener('visibilitychange', this.checkAuthToken)
  }
}
