import { NgModule } from '@angular/core';
import { TokenValidatorService } from './token-validator.service';

@NgModule({
  providers: [
    TokenValidatorService
  ]
})
export class TokenValidatorModule {
  constructor(private tokenValidatorService: TokenValidatorService) {
    this.tokenValidatorService.init();
  }
}
